/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { global } from '../global';

/**
 * Find out whether or not the given argument is an element that would react somewhat normally to DOM-manipulations.
 *
 * @since 3.7.0
 * @param {*} element - The element to check.
 * @returns {boolean} `true` if the given argument is an element (or document, or window), and `false` otherwise.
 */
export function isElement(element) {
    return element instanceof Element || element instanceof Document || element instanceof Window;
}

/**
 * Get the current scroll values of the given element (or window). Will return an object containing
 * "left" and "top" properties, which are set to the scroll values, or false if no compatible element
 * was given.
 *
 * @param {Element|Window} [element=window]
 * @returns {{ left: number, top: number } | boolean}
 */
export function getElementScroll(element = window) {
    if (isElement(element)) {
        if (element instanceof Window) {
            return {
                left: element.pageXOffset || document.documentElement.scrollLeft,
                top: element.pageYOffset || document.documentElement.scrollTop,
            };
        } else {
            return {
                left: element.scrollX || element.scrollLeft,
                top: element.scrollY || element.scrollTop,
            };
        }
    } else {
        return false;
    }
};

/* 
Lock the DOM if the modal is open to prevent scrolling
Then release it again after modal closed
*/
const scrollLockClass = 'scroll-lock';
let scrollTop = 0;
export let isScrollLocked = false;

const addScrollLock = () => {
    if (!isScrollLocked) {
        // Get scroll position
        const scrollPosition = getElementScroll();
        global.DOC.style.marginTop = `${-scrollPosition.top}px`;
        global.DOC.classList.add(scrollLockClass);
        // Remember state
        isScrollLocked = true;
        scrollTop = scrollPosition.top;
    }
};

const removeScrollLock = () => {
    if (isScrollLocked) {
        const scrollPosition = getElementScroll();
        global.DOC.classList.remove(scrollLockClass);
        global.DOC.style.marginTop = '';
        global.DOC.style.marginLeft = '';
        // Set the scroll position to what it was before
        window.scrollTo(scrollPosition.left, scrollTop);
        // Remember state
        isScrollLocked = false;
    }
};

/*
Function to convert px to %
*/
const getPercentage = (value) => {
    const vw = document.documentElement.clientWidth || window.innerWidth;
    const isPX = value?.endsWith('px');
    if (isPX) {
        value = value?.split('px')[0];
        const result = Math.floor(100 * value / vw);
        return result + '%';
    } else {
        return;
    }
};

/* Ajax loaders */
const loadingClass = 'loading';

const setAjaxLoading = (elem) => {
    elem = elem || global.DOC;
    elem.classList.add(loadingClass);
};

const setAjaxComplete = (elem) => {
    elem = elem || global.DOC;
    elem.classList.remove(loadingClass)
};

/* scroll functions */
const scrollToPoint = (elem) => elem.scrollTo({ top: 0, left: 0});

/* is posts page */
const isPostsPage = global.DOC.classList.contains('index') ||
    global.DOC.classList.contains('admin') ||
    global.DOC.classList.contains('posts') ||
    global.DOC.classList.contains('tags') ||
    global.DOC.classList.contains('data') ||
    global.DOC.classList.contains('cache');

const replaceSpaces = (elem) => elem.replace(/\s/g, '_');

const replaceUnderscores = (elem) => elem.replace(/_/g, ' ');

export {
    addScrollLock,
    removeScrollLock,
    getPercentage,
    scrollToPoint,
    isPostsPage,
    replaceSpaces,
    replaceUnderscores,
    setAjaxLoading,
    setAjaxComplete
};
