/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

const restrictIntegerInput = (elem) => {
    elem.addEventListener('keypress', (event) => {
        if (isNaN(event.key)) {
            event.preventDefault();
        }
    });
};

export { restrictIntegerInput };