/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { global } from '../global';
import { env } from '../env';
import { setAjaxLoading, setAjaxComplete } from '../utils/utils';
import { removeChildNodes } from '../utils/removeChildNodes';

const modal = global.DOC.querySelector('.modal');
const modalContent = modal.querySelector('.modal__content--inner');
const parser = new DOMParser();
let doc;

const navigation = () => {
    const navigationItems = global.DOC.querySelectorAll('.main-nav__ref');
    const url = env.apiPostExternalContentURL;
    navigationItems.forEach(item => {
        item.addEventListener('click', (event) => {
            event.preventDefault();
            setAjaxLoading(modal);
            const href = event.target.getAttribute('href').split('/')[1];
            const file = { fileName : href };
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(file)
            };
            fetch(url, options)
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 200) {
                        updateContent(data.body);
                    } else {
                        const template = `<div id="container">Sorry, there was a network error. You can <a href="/">try again</a> perhaps.</div>`;
                        modalContent.insertAdjacentHTML('beforeend', template);
                        console.log(`Uh oh. Status: ${data.status}`);
                    }
                    setAjaxComplete(modal);
                })
                .catch((err) => {
                    console.log(err.status);
                    setAjaxComplete(modal);
                });
        });
    });
};

const updateContent = input => {
    doc = parser.parseFromString(input, 'text/html'); 
    const container = doc.querySelector('#container');
    removeChildNodes(modalContent);
    modalContent.appendChild(container);
};

export { navigation };