/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

let max;
let elemMax;
let elemHeight;

const cssGetHeightProperty = (elems) => {
    let arr = [];
    [...elems]?.forEach(item => {
        let top = item?.style?.top;
        if (top) {
            top.split('px')[0];
            top = parseInt(top);
        }
        arr.push(top);
    });
    elemMax = [...elems]?.reduce((a, b) => {
        return parseInt(b.style.top) > parseInt(a.style.top) ? b : a;
    });
    elemHeight = cssGetElementHeight(elemMax);
    arr = [...new Set(arr)];
    max = Math.max(...arr);
    return max;
};

const cssGetElementHeight = (elem) => {
    return elem.clientHeight ? elem.clientHeight : 0
};

const cssSetElementHeight = () => {
    const root = document.documentElement;
    return root.style.setProperty(
        '--list-height', `${max + elemHeight + 80}px`
    );
};

export { cssGetHeightProperty, cssSetElementHeight };