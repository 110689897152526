/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { global } from '../global';
import { throttle } from '../utils/throttle';
import { getDocumentWidth } from '../utils/getDocumentWidth';

const mobileClass = 'index-posts__responsive'

const setResponsive = () => {
    let { mobile } = getDocumentWidth();
    const boxes = global.DOC.querySelectorAll('ul.index-posts > li');
    boxes.forEach(item => {
        if (mobile) {
            item.classList.add(mobileClass);
        } else {
            item.classList.remove(mobileClass);
        }
    });
};

onload = (() => { 
    getDocumentWidth();
    throttle(setResponsive());
})();

window.addEventListener('resize', () => {
    getDocumentWidth();
    throttle(setResponsive());
});

window.addEventListener('orientationchange', () => {
    getDocumentWidth();
    throttle(setResponsive());
});

export { setResponsive };