/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { global } from '../global';
import { addScrollLock, removeScrollLock, scrollToPoint } from '../utils/utils';
import { removeChildNodes } from '../utils/removeChildNodes';
import { setImageLoader } from '../utils/setImageLoader';

const modal = () => {
    const modalClass = 'modal';
    const openClass = `${modalClass}__open`;
    const closeClass = `${modalClass}__close`;
    let modalElem;
    let modalContent;
    let modalList;

    document.addEventListener('click', (event) => {
        setImageLoader();
        modalElem = global.DOC.querySelector(`.${modalClass}`);
        modalContent = global.DOC.querySelector(`.${modalClass}__content`);
        modalList = global.DOC.querySelector(`.${modalClass}__list`);
        const current = event.target.closest('.post-trigger');
        if (current && modalElem) {
            modalElem.classList.add(openClass);
            scrollToPoint(modalContent);
            scrollToPoint(modalList);
            addScrollLock();
        }
    });

    document.addEventListener('click', (event) => {
        const close = event.target.classList.contains(closeClass);
        if (close && modalElem) {
            closeModal();
        }
    });

    window.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            closeModal();
        }
    });

    const closeModal = () => {
        const modalContentInner = global.DOC.querySelector(`.${modalClass}__content--inner`)
        modalElem.classList.remove(openClass);
        scrollToPoint(modalContent);
        scrollToPoint(modalList);
        removeChildNodes(modalContentInner);
        removeChildNodes(modalList);
        removeScrollLock();
    };
};

export { modal };
