/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

const env = {
    oAuthConsumerKey: atob('MW5KZmtyMG9XZzBzTmxHS0ttNXB4NG43TVBETWFZb2x1R0NwbkZuRk1WejlJbHlIUFc='),
    oAuthConsumerKeyDev: atob('S1FBTHlqZFlQdGJkTjZqTUVBQ0lXVElxVG9HQWRGRTRJRzRDZXVLTjRKaDBuV2djbU8='),
    apiBaseURL: 'api.tumblr.com/v2/blog/',
    apiBlog: 'jessicaharbydotcom.tumblr.com',
    blogURL: 'https://jessicaharbydotcom.tumblr.com/',
    apiController: '/api/API.cfc'
};

env.apiGetURL = `${env.apiController}?method=getResponse`;
env.apiPostURL = `${env.apiController}?method=postResponse`;
env.apiPostExternalContentURL = `${env.apiController}?method=postExternalContent`;

export { env };