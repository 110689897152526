import styles from './scss/main.scss';

import { global } from './js/global';

/* utils */
import { isPostsPage } from './js/utils/utils';

/* components */
import { navigation } from './js/components/navigation';
import { modal } from './js/components/modal';
import { getPosts } from './js/components/getPosts';
import { admin } from './js/components/admin';
import './js/components/setDraggable';

document.addEventListener('DOMContentLoaded', () => {
    global.DOC.classList.remove('no-js');
    navigation();
    modal();
    if (isPostsPage) {
        getPosts();
    }
    admin();
});
