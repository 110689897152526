/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

const setAltTextProperty = (elems) => {
    [...elems].forEach(item => {
        const title = item.querySelector('a').getAttribute('aria-label');
        const img = item.querySelectorAll('img');
        [...img].forEach(imgItem => {
            const alt = imgItem.getAttribute('alt');
            if (title && !alt) {
                imgItem.setAttribute('alt', title);
            }
        });
    });
}

export { setAltTextProperty };