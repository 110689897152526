/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { global } from '../global';

const navigationToggle = () => {
    const wrapper = global.DOC.querySelector('.main-nav__toggle');
    if (wrapper) {
        global.DOC.addEventListener('keydown', (event) => {
            if (event.key === 'x' || event.key === 'X') {
                wrapper.classList.toggle(global.hiddenClass);
            }
        });
    }
};

export { navigationToggle };