/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { global } from '../global';

const getDocumentWidth = () => {
    let isDesktop = false;
    let isTablet = false;
    let isMobile = false;
    const vw = document.documentElement.clientWidth || window.innerWidth;
    if (vw >= global.site.size.lg) {
        isDesktop = true;
    } else if (
        vw <= global.site.size.lg &&
        vw >= global.site.size.md
    ) {
        isTablet = true;
    } else {
        isMobile = true;
    }

    return {
        mobile: isMobile,
        tablet: isTablet,
        desktop: isDesktop
    }
}

export { getDocumentWidth };
