/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { global } from '../global';
import { mediaControlsRemoveAutoPlay } from '../utils/mediaControls';

const postPagePosts = () => {
    const boxes = global.DOC.querySelectorAll('ul.posts video');
    mediaControlsRemoveAutoPlay(boxes);
};   

export { postPagePosts };