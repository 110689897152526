/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { global } from '../global';
import { env } from '../env';
import { setAjaxLoading, setAjaxComplete } from '../utils/utils';
import { cssGetHeightProperty, cssSetElementHeight } from '../utils/setCSSProperties';
import { restrictIntegerInput } from '../utils/restrictIntegerInput';
import { indexPagePosts } from './indexPagePosts';

const indexPage = global.DOC.classList.contains('index');
const adminPage = global.DOC.classList.contains('admin');
const statusOKClass = 'status__ok';
const statusBadClass = 'status__bad';
let objLayout = {};
let arrLayout;
let dataLayout;
let statusElem;
let postCount;

const loadLayoutData = () => {
    const url = env.apiGetURL;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            if (data.status === 200) {
                dataLayout = JSON.parse(data.body);
            } else {
                console.log(`Uh oh. Status: ${data.status}`);
            }
        })
        .catch((err) => console.log(err.status))
        .finally(() => postsLayout());
};

const postsLayout = () => {
    const postItems = global.DOC.querySelectorAll('ul.index-posts > li');
    postCount = global.DOC.querySelector('#postCount');
    postItems.forEach((item) => {
        return dataLayout.data.map(subItem => {
            if (item.id === subItem.id) {
                item.style.height = subItem.height;
                item.style.top = subItem.top;
                item.style.left = subItem.left;
                item.style.width = subItem.width;
            }
            item.classList.add('fade-in');
        });
    });
    if (postCount) {
        postCount.value = dataLayout.postCount || 1;
        restrictIntegerInput(postCount);
    }
    cssGetHeightProperty(postItems);
    cssSetElementHeight();
    if (indexPage || adminPage) {
        indexPagePosts();
    }
};

global.DOC.addEventListener('click', (event) => {
    const href = event.target.href && event.target.getAttribute('href') === 'save';
    if (href) {
        event.preventDefault();
        if (postCount.value !== null && postCount.value !== '') {
            const boxes = global.DOC.querySelectorAll('ul.index-posts > li');
            statusElem = global.DOC.querySelector('.admin .icon__status');
            setAjaxLoading();
            arrLayout = [];
            boxes.forEach((item) => {
                const layout = {
                    id: item.id,
                    height: item.style.height,
                    left: item.style.left,
                    top: item.style.top,
                    width: item.style.width,
                };
                arrLayout.push(layout);
            });
            objLayout = { data: arrLayout, postCount: postCount.value};
            submitLayoutData();
        } else {
            postCount.classList.add(global.errorClass);
            return;
        }
    }
});

const submitLayoutData = () => {
    resetStatus();
    const url = env.apiPostURL;
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(objLayout)
    };
    fetch(url, options)
        .then((data) => {
            if (data.status === 200) {
                statusElem.classList.add(statusOKClass);
                postCount.classList.remove(global.errorClass);
            } else {
                statusElem.classList.add(statusBadClass);
                console.log(`Uh oh. Status: ${data.status}`);
                setAjaxComplete();
            }
        })
        .catch((err) => console.log(err.status))
        .finally(() => {
            loadLayoutData();
            setAjaxComplete();
            resetStatusTimeout(1000);
        });
};

const resetStatus = () => {
    statusElem.classList.remove(...[statusOKClass, statusBadClass]);
};

const resetStatusTimeout = (interval) => setTimeout(() => {
    resetStatus();
}, interval);

export { loadLayoutData };