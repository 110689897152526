/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { getPosts } from './getPosts';

const refreshPosts = () => {
    document.addEventListener('click', (event) => {
        if (event.target.classList.contains('refresh')) {
            event.preventDefault();
            getPosts();
        }
    });
}

export { refreshPosts };