/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

const global = {
    protocol: document.location.protocol,
    docLocation: document.location,
    devURL: 'http://dev.jessicaharby.com',
    liveURL: 'http://www.jessicaharby.com',
    DOC: document.querySelector('html'),
    errorClass: 'error',
    hiddenClass: 'hidden',
    site: {
        size: {
            'xxs': 1,
            'xs': 480,
            'sm': 768,
            'md': 992,
            'lg': 1220,
            'xl': 1540,
            'xxl': 1840,
            'xxxl': 1920
        }
    }
};

export { global };
