/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { global } from '../global';
import { env } from '../env';
import { replaceSpaces, replaceUnderscores, setAjaxLoading, setAjaxComplete, scrollToPoint } from '../utils/utils';
import { addToSessionStorage, retrieveFromSessionStorage } from '../utils/utilsStorage';
import { removeChildNodes } from '../utils/removeChildNodes';
import { mediaControlsRemoveAutoPlay, mediaControlUnmute } from '../utils/mediaControls';
import { postPagePosts } from './postPagePosts';
import { getPostTypes } from '../utils/getPostType';
import { loadLayoutData } from './setDraggable';
import { refreshPosts } from './refreshPosts';

const postWrapper = global.DOC.querySelector('ul.posts');
const postIndexWrapper = global.DOC.querySelector('ul.index-posts');
const tagWrapper = global.DOC.querySelector('ul.tags');
const dataWrapper = global.DOC.querySelector('pre.data');
const fadeInClass = 'fade-in--fast';
const parser = new DOMParser();
let doc;
let indexPage;
let adminPage;
let postsPage;
let cachePage;
let tags = [];
let arrTags = [];
let allPosts = [];
let resultFinal = [];
let filteredPosts = [];
let apiKey;

if (global.docLocation.origin === global.devURL) {
    apiKey = env.oAuthConsumerKeyDev;
} else {
    apiKey = env.oAuthConsumerKey;
}

const getPosts = () => {
    if (postIndexWrapper) {
        removeChildNodes(postIndexWrapper);
    }
    let limit = 20;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const retrieveMore = (offset) => {
        const url = new URL(`https://${env.apiBaseURL}${env.apiBlog}/posts?offset=${offset}`);
        url.searchParams.set('api_key', apiKey);
        setAjaxLoading();
        fetch(url, options)
            .then((response) => response.json())
            .then((response) => {
                indexPage = global.DOC.classList.contains('index');
                adminPage = global.DOC.classList.contains('admin');
                postsPage = global.DOC.classList.contains('posts');
                cachePage = global.DOC.classList.contains('cache');
                if (response) {
                    const posts = response?.response?.posts;
                    const totalPosts = response?.response?.total_posts;
                    const postLength = posts.length;
                    posts.forEach((item) => {
                        allPosts.push(item);
                        doc = parser.parseFromString(item.body ?? '', 'text/html');
                        let type = getPostTypes(doc);
                        if (postWrapper) {
                            const typeString = `el ${type}`;
                            const template = `<li class='${typeString}'><a href=${item.post_url}?showPost=true target='_blank'>${item.body}</a></li>`;
                            postWrapper.insertAdjacentHTML('beforeend', template);
                        }
                    });

                    if (dataWrapper) {
                        dataWrapper.textContent = JSON.stringify(posts[0], null, 4);
                        setAjaxComplete();
                        return;
                    }

                    // /*
                    // Lets keep pushing all of the tags to the tag array
                    // */
                    posts.map((item) => item.tags.map((tag) => tags.push(tag.toLowerCase())));

                    /* 
                    As long as our total no of posts is greater than our counter keep iterating over the posts
                    */
                    if (totalPosts >= offset && postLength !== 0) {
                        retrieveMore(offset + limit);
                    } else if (offset >= totalPosts) {
                        /* 
                        Once our counter is larger or the same size as
                        the total number of posts, 
                        lets output the array of unique tags. 
                        */
                        arrTags = [...new Set(tags.sort())];
                        setPostsByType();
                        sortPostsByDate();
                        attachClickEvent();
                        setAjaxComplete();
                        setFinalPosts();
                        if (tagWrapper) {
                            setTags();
                        }
                        if (postsPage) {
                            postPagePosts();
                        }
                    }
                } else {
                    errorRefreshPosts('an error');
                    setAjaxComplete();
                }
            })
            .catch(() => {
                errorRefreshPosts('an error');
                setAjaxComplete();
            })
            .finally(() => {});
    };
    retrieveMore(0);
};

const setPostsByType = () => {
    filteredPosts = allPosts.map((item) => {
        let videoPosterSM = '';
        let videoPosterXL = '';
        doc = parser.parseFromString(item.body ?? '', 'text/html');
        let type = getPostTypes(doc);
        return {
            id: item.id,
            post_id: `postid__${item.id}`,
            body: item.body,
            video_poster_sm: videoPosterSM,
            video_poster_xl: videoPosterXL,
            type,
            tags: item.tags,
            date: item.date
        }
    });
};

function updatePostsByType() {
    return Promise.all(filteredPosts.map(async item => {
        let videoPosterSM = '';
        let videoPosterXL = ''
        doc = parser.parseFromString(item.body ?? '', 'text/html');
        let type = getPostTypes(doc);
        if (item.type.includes('video-embed') && item.body.includes('vimeo')) {
            let videoID = doc.querySelector('a')?.getAttribute('href')?.split('vimeo.com/')[1] 
            ||
            doc.querySelector('[data-url]')?.getAttribute('data-url')?.split('vimeo.com/')[1];
            if (videoID && videoID.includes('?')) {
                videoID = videoID.split('?')[0];
            }
            try {
                const [{thumbnail_large, thumbnail_small}]  = await fetch(`https://vimeo.com/api/v2/video/${videoID}.json`)
                    .then(response => response.json());
                videoPosterSM = thumbnail_small + '.jpg';
                videoPosterXL = thumbnail_large + '.jpg';
            } catch(error) {
                console.log(error);
            }
        } else if (item.type === 'video') {
            const videoItem = doc.querySelector('video');
            let videoID = videoItem.getAttribute('poster').replace('_frame1', '_frame2');
            videoPosterSM = videoID;
            videoPosterXL = videoID;

        }
        return {
            id: item.id,
            post_id: `postid__${item.id}`,
            body: item.body,
            video_poster_sm: videoPosterSM,
            video_poster_xl: videoPosterXL,
            type,
            tags: item.tags,
            date: item.date
        }
    }));
};

const sortPostsByDate = () => {
    filteredPosts.sort((a, b) => Date.parse(b.date) - Date.parse(a.date)); 
};

const setTags = () => {
    arrTags.forEach((item) => {
        const itemHREF = item.replaceAll(' ', '+');
        const template = `<li><a href=${env.blogURL}/tagged/${itemHREF}?showPost=true target='_blank'>${item}</li>`;
        tagWrapper.innerHTML += template;
    });
};

function setAllPostsToObjects() {
    filteredPosts = retrieveFilteredPostsFromStorage();
    const newSets = arrTags.map((item) => filteredPosts.filter((postItem) => postItem.tags[0] === item));
    resultFinal = arrTags.map((key, i) => {
        key = replaceSpaces(key);
        return { name: key, value: newSets[i] };
    });
};

function setFinalPosts() {
    updatePostsByType().then(filteredPosts => {
        addToSessionStorage('filteredPosts', filteredPosts);
        setAllPostsToObjects();
        if (
            indexPage ||
            adminPage ||
            cachePage
        ) {
            postIndexWrapper.classList.remove(global.hiddenClass);
            setIndexPagePosts();
        }
    });
}

const attachClickEvent = () => {
    const modalContentWrapper = global.DOC.querySelector('.modal__content');
    const modalContent = global.DOC.querySelector('.modal__content--inner');
    const modalList = global.DOC.querySelector('.modal__list');
    global.DOC.addEventListener('click', (event) => {
        const current = event.target.closest('.post-trigger');
        if (current) {
            event.preventDefault();
            return resultFinal.forEach((item, index) => {
                const itemName = replaceUnderscores(item.name);
                const targetName = replaceUnderscores(current.getAttribute('href'));
                let setName = resultFinal[index].value;
                if (itemName === targetName) {
                    const currentID = parseInt(current.id.split('#')[1]);
                    const selectedItem = setName.filter((item) => item.id === currentID);
                    const getSelectedIndex = setName.findIndex((item) => item.id === currentID);
                    const firstItem = selectedItem[0].body;
                    setName.unshift(setName.splice(getSelectedIndex, 1)[0]);
                    setName.forEach((item) => {
                        doc = parser.parseFromString(item.body ?? '', 'text/html');
                        let type = getPostTypes(doc);
                        let template;
                        let typeString = `${type} thumbnail-trigger`;
                        if (type === 'image') {
                            const image = doc.querySelector('img');
                            const altText = image.getAttribute('alt');
                            const thumbnail = image.srcset.split(',')[0].split(' ')[0];
                            template = `
                                <li>
                                    <a href='#${item.id}' class='${typeString}'>
                                        <img src=${thumbnail} alt='${altText ? altText : "Jessica Harby Artist"}' />
                                    </a>
                                </li>
                            `;
                        } else if (type.includes('video')) {
                            template = `
                                <li>
                                    <a href='#${item.id}' class='${typeString}'>
                                        <img src='${item.video_poster_sm}' />
                                    </a>
                                </li>
                            `;
                        } else {
                            template = `
                                <li>
                                    <a href='#${item.id}' class='${typeString}'>
                                        <p>${item.body}</p>
                                    </a>
                                </li>
                            `;
                        }
                        modalList.insertAdjacentHTML('beforeend', template);
                    });
                    modalContent.insertAdjacentHTML('beforeend', firstItem);
                }
            });
        }
    });

    global.DOC.addEventListener('click', (event) => {
        filteredPosts = retrieveFilteredPostsFromStorage();
        const current = event.target.closest('.thumbnail-trigger');
        if (current) {
            event.preventDefault();
            modalContent.classList.remove(fadeInClass);
            removeChildNodes(modalContent);
            const href = current.href.split('#')[1];
            filteredPosts.forEach(() => {
                const newPost = filteredPosts.filter((item) => item.id.toString() === href);
                modalContent.innerHTML = newPost[0].body;
            });
            scrollToPoint(modalContentWrapper);
        }
    });

    global.DOC.addEventListener('click', (event) => {
        if (event.target?.classList.contains('video') || event.target.closest('li')?.classList.contains('video')) {
            const videos = modalContent.querySelectorAll('video');
            if (videos) {
                mediaControlUnmute(videos);
                mediaControlsRemoveAutoPlay(videos);
            }
        }
    });
};

function setIndexPagePosts() {
    let postCount;
    const url = env.apiGetURL;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.status === 200) {
                data = JSON.parse(data.body);
                postCount = data.postCount;
                outputPostsToPage();
            } else {
                errorRefreshPosts('a network error');
                console.log(`Uh oh. Status: ${data.status}`);
            }
        })
        .catch((err) => console.log(err.status));

    const outputPostsToPage = () => {
        filteredPosts = retrieveFilteredPostsFromStorage();
        filteredPosts.forEach((item, index) => {
            if (index + 1 <= postCount) {
                doc = parser.parseFromString(item.body ?? '', 'text/html');
                let type = getPostTypes(doc);
                const tagName = item.tags[0];
                const tag = replaceSpaces(tagName);
                let inner;
                if (type === 'image') {
                    const image = doc.querySelector('img');
                    let imageXL = image?.srcset?.split(',');
                    imageXL = imageXL[imageXL.length - 2];
                    if (imageXL) {
                        imageXL = imageXL.trim();
                        imageXL = imageXL.split(' ')[0];    
                    }
                    inner = `<img src=${imageXL} />`;
                } else if (item.type.includes('video') && item.video_poster_xl) {
                    inner = `<img src='${item.video_poster_xl}' />`;
                } else {
                    inner = item.body;
                }
                const template = `
                    <li id=${item.post_id} class='${type}'>
                        <a id='#${item.id}' href='${tag}' class='post-trigger' aria-label='Project title: ${tagName}'>
                            ${inner}
                        </a>
                    </li>
                `;
                postIndexWrapper.insertAdjacentHTML('beforeend', template);
            }
        });
        loadLayoutData();
    }
};

const errorRefreshPosts = (type) => {
    if (postIndexWrapper) {
        removeChildNodes(postIndexWrapper);
    }
    const message = `<li class="index-posts__error">Sorry there was ${type}. You can <a href="/" class="refresh">try again</a> perhaps.</li>`;
    postIndexWrapper.insertAdjacentHTML('beforeend', message);
    postIndexWrapper.classList.remove(global.hiddenClass);
    refreshPosts();
};

const retrieveFilteredPostsFromStorage = () => retrieveFromSessionStorage('filteredPosts');

export { getPosts };
