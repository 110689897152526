/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { global } from '../global'

const setImageLoader = () => {
    const modal = global.DOC.querySelector('.modal');
    const modalContent = modal?.querySelector('.modal__content--inner');
    const media = modalContent?.querySelectorAll('img, iframe, audio'); // TODO maybe take out audio and iframe?. 
    const loadingClass = 'loading';
    const fadeInClass = 'fade-in--fast';
    modal.classList.add(loadingClass);

    function loaded() {
        modal.classList.remove(loadingClass);
        modalContent.classList.add(fadeInClass);
    };

    if ([...media].length >= 1) {
        [...media].forEach(item => {
            if (item && item.complete) {
                loaded();
            } else if (!item) {
                loaded();
            } else {
                item?.addEventListener('load', loaded)
                item?.addEventListener('error', function() {
                    console.log('error loading image');
                })
            };
        });
    } else {
        loaded();
    }

};

export { setImageLoader };
