/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { global } from '../global';
import { attachDraggable } from './attachDraggable';
import { navigationToggle } from './navigationToggle';
const adminOnClass = 'admin-on';

const admin = () => {
    global.DOC.addEventListener('click', (event) => {
        const href = event.target.href && event.target.getAttribute('href') === 'admin';
        if (href) {
            event.preventDefault();
            if (global.DOC.classList.contains(adminOnClass)) {
                global.DOC.classList.remove(adminOnClass);
            } else {
                global.DOC.classList.add(adminOnClass);
                attachDraggable();
            }
        }
    });
    navigationToggle();
};

export { admin };
