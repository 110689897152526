/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

import { global } from '../global';
import { getPercentage } from '../utils/utils';
import { setResponsive } from './setResponsive';
import { setAltTextProperty } from '../utils/setAltTextProperty';

const indexPagePosts = () => {
    const boxes = global.DOC.querySelectorAll('ul.index-posts > li');
    setAltTextProperty(boxes);
    boxes.forEach(item => {
        let left = item?.style?.left;
        let width = item?.style?.width;
        left ? item.style.left = getPercentage(left) : null;
        width ? item.style.width = getPercentage(width) : null;
    });
    setResponsive();
}

export { indexPagePosts };