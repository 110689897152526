/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

const removeChildNodes = (elem) => {
    elem.replaceChildren();
};

export { removeChildNodes };