/*
Luke Harby
slackwise LTD
https://slackwise.org.uk
2012 - present
*/

const mediaControlsRemoveAutoPlay = (elems) => {
    elems.forEach(item => {
       item.removeAttribute('autoplay'); 
       item.pause();
    });
};

const mediaControlUnmute = (elems) => elems.forEach(item => item.muted = false);

export { 
    mediaControlsRemoveAutoPlay,
    mediaControlUnmute
};